
import BasicPage from '@/components/BasicPageLayout/BasicPage.vue'
import { defineComponent, onMounted, reactive, ref } from 'vue-demi'
import { getProductSetting, updateProductSetting } from '@/api/production'
import { ElMessage } from 'element-plus'

export default defineComponent({
  components: {
    BasicPage
  },
  setup() {

    const settingList = ref<AnyArray>([])
    onMounted(async () => {
      const settingListResp = await getProductSetting()
      settingList.value = settingListResp.data.data
      formDataList.value = settingList.value.map(s => s.value)
    })

    const onModify = (idx: number) => {
      modifyedIdxSet.add(idx)
    }

    const onSubmit = async () => {
      const updateList: Promise<any>[] = []
      modifyedIdxSet.forEach(idx => {
        updateList.push(updateProductSetting({
          id: settingList.value[idx].id,
          name: settingList.value[idx].name,
          value: formDataList.value[idx],
          desc: settingList.value[idx].desc,
        }))
      })
      await Promise.all(updateList)
      ElMessage.success('修改成功')
    }

    const modifyedIdxSet = new Set<number>([])

    const formDataList = ref<AnyArray>([])

    return {
      onModify,
      settingList,
      formDataList,
      onSubmit
    }
  }
})
