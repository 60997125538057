
import { computed, defineComponent, onMounted, ref } from 'vue'
export default defineComponent({
  props: {
    margin: {
      type: [String, Number, Array],
      default: '0'
    },
    borderRadius: {
      type: String,
      default: '0'
    },
    boxShadow: String
  },

  setup(props, {slots}) {

    const containerRef = ref()
    const clientTop = ref(0)
    onMounted(() => {
      clientTop.value = containerRef.value.getBoundingClientRect().top
    })

    const marginBottom = ref(0)
    const margin = computed(() => {
      const mg = props.margin
      if (typeof mg === 'number') {
        marginBottom.value = mg
        return `${mg}px`
      } else if (Array.isArray(mg)) {
        let res = ''
        mg[0] && (res += mg[0] + 'px')
        mg[1] && (res += ' ' + mg[1] + 'px')
        mg[2] && (res += ' ' + mg[2] + 'px')
        mg[3] && (res += ' ' + mg[3] + 'px')
        marginBottom.value = (mg[2] || mg[0]) as number
        return res
      } else {
        marginBottom.value = Number(mg.replace('px', ''))
        return mg
      }
    })

    return () => (
      <div 
        class="content-container" 
        ref={containerRef} 
        style={{
          minHeight: `calc(100vh - ${clientTop.value}px - ${marginBottom.value}px)`, 
          overflow: 'hidden', 
          margin: margin.value,
          borderRadius: props.borderRadius,
          boxShadow: props.boxShadow
        }}>
        {slots.default?.()}
      </div>
    )
  }
})
